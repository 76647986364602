import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"
import Header from "../Header/header"
import Footer from "../Footer/footer"
import "./layout.scss"
import "../../styles/globals.scss"

export default function Layout({ children }) {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
					description
					author
				}
			}
		}
	`)

	return (
		<>
			<Helmet>
			<script
			src="/stats/js/script.js"
			data-api="/stats/api/event"
			data-domain="fynnfaber.ch"
			></script>
			{/* <script defer data-domain="fynnfaber.ch" src="https://plausible.io/js/script.js"></script> */}

			</Helmet>
			<div className="Layout">
				<Header
					className="Layout__header"
					siteTitle={data.site.siteMetadata.title}
				/>
				<main className="Layout__children">{children}</main>
				<Footer
					className="Layout__footer"
					siteTitle={data.site.siteMetadata.title}
					siteAuthor={data.site.siteMetadata.author}
				/>
			</div>
		</>
	)
}
