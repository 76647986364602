import { Link } from "gatsby"
import React from "react"
import { slide as Menu } from "react-burger-menu"
import {SocialLinks} from "../SocialLinks/sociallinks"
import {Home} from "../../images/icons"

import "./header.scss"

const Header = props => (
	<header className={`Header ${props.className}`}>
		<Link to="/" className="Header__logo">
			<Home/>
		</Link>
		<Menu right className="Header__menu" width = {330}>
			<Link to="/" className="Header__menu__item menuTitle">
				{!props.siteTitle || props.siteTitle === ""
					? `Undefined`
					: props.siteTitle}
			</Link>
			<SocialLinks className="Header__SocialLinks" />
			<Link to="/about" className="Header__menu__item">
				About Me
			</Link>
			<Link to="/all-posts" className="Header__menu__item">
				Posts
			</Link>
			{/* <Link to="/example-page" className="Header__menu__item">
				Example Page
			</Link> */}
		</Menu>
	</header>
)

export default Header
