import React from "react";
import "./sociallinks.scss";
const he = require("he");

import { FaGithub, FaTwitter, FaEnvelope, FaLinkedin } from "react-icons/fa";
import { CV } from "../../images/icons";
import { ImProfile } from "react-icons/im";
import Tooltip from "../../tooltip/Tooltip";

const socialMediaAccounts = [
  {
    id: "cv",
    icon: CV,
    url: "https://drive.google.com/uc?export=download&id=14UsWV2IxKeeP2ybU3wJGLZPQUz0ds8iZ",
    tooltip: "Download CV",
	hidden: false
  },
  { id: "github", icon: FaGithub, url: "http://github.com/faberf", tooltip: "GitHub", hidden: false },
  { id: "linkedin", icon: FaLinkedin, url: "https://www.linkedin.com/in/fynn-faber/", tooltip: "LinkedIn", hidden: false },
  {
    id: "email",
    icon: FaEnvelope,
    url: he.encode("mailto:fynn.f.faber@proton.me", {
      encodeEverything: true,
    }),
    tooltip: "Email", hidden: true
  },
  { id: "twitter", icon: FaTwitter, url: "https://twitter.com/fynnfa", tooltip: "Twitter", hidden:false },
];

const RedirectLink = (encodedUrl) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (e.button === 0) { // Only prevent default action on left-click
      e.preventDefault();
      window.open(he.decode(encodedUrl), "_blank");
    }
  };

  return handleClick;
};


const SocialLinks = ({ className }) => (
	<div className={`SocialLinks ${className}`}>
	  {socialMediaAccounts.map((account) =>
		account.hidden ? (
		<Tooltip text={account.tooltip}>

		<a
			onClick={RedirectLink(account.url)}
			key={account.id}
			className={`SocialLinks__container ${className}__container`}
		  >
			<account.icon className={`SocialLinks__icon ${className}__icon`} />
		  </a>
		</Tooltip>
		  
		) : (
			<Tooltip text={account.tooltip}>
				<a
				href={account.url}
				onClick={RedirectLink(account.url)}
				key={account.id}
				className={`SocialLinks__container ${className}__container`}
				rel="noopener noreferrer"
			>
				<account.icon className={`SocialLinks__icon ${className}__icon`} />
			</a>
			</Tooltip>
		  
		)
	  )}
	</div>
  );
  


const SocialLink = ({ identifier, text }) => {
	const account = socialMediaAccounts.find((acc) => acc.id === identifier);
  
	if (!account) {
	  return null;
	}
  
	return account.hidden ? (
	  <Tooltip text={text}>
		<a
		className="SocialLinks__link"
		onClick={RedirectLink(account.url)}
        key={account.id}
      >
        {text}
      </a>
    </Tooltip>
  ) : (
    <Tooltip text={text}>
      <a
		className="SocialLinks__link"
        href={account.url}
        onClick={RedirectLink(account.url)}
        key={account.id}
        rel="noopener noreferrer"
      >
        {text}
      </a>
    </Tooltip>
  );
};

export default SocialLinks
export { SocialLinks, SocialLink };