import React from 'react';
import './Tooltip.scss';

interface TooltipProps {
  text: string;
  children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ text, children }) => {
  return (
    <div className="Tooltip">
      {children}
      <span className="Tooltip__text">{text}</span>
    </div>
  );
};

export default Tooltip;
