import React from "react";



function Twitter() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path d="M32 7.075a12.941 12.941 0 01-3.769 1.031 6.601 6.601 0 002.887-3.631 13.21 13.21 0 01-4.169 1.594A6.565 6.565 0 0022.155 4a6.563 6.563 0 00-6.563 6.563c0 .512.056 1.012.169 1.494A18.635 18.635 0 012.23 5.195a6.56 6.56 0 00-.887 3.3 6.557 6.557 0 002.919 5.463 6.565 6.565 0 01-2.975-.819v.081a6.565 6.565 0 005.269 6.437 6.574 6.574 0 01-2.968.112 6.588 6.588 0 006.131 4.563 13.17 13.17 0 01-9.725 2.719 18.568 18.568 0 0010.069 2.95c12.075 0 18.681-10.006 18.681-18.681 0-.287-.006-.569-.019-.85A13.216 13.216 0 0032 7.076z"></path>
    </svg>
  );
}

function LinkedIn() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path d="M29 0H3C1.35 0 0 1.35 0 3v26c0 1.65 1.35 3 3 3h26c1.65 0 3-1.35 3-3V3c0-1.65-1.35-3-3-3zM12 26H8V12h4v14zm-2-16c-1.106 0-2-.894-2-2s.894-2 2-2c1.106 0 2 .894 2 2s-.894 2-2 2zm16 16h-4v-8c0-1.106-.894-2-2-2s-2 .894-2 2v8h-4V12h4v2.481C18.825 13.35 20.087 12 21.5 12c2.488 0 4.5 2.238 4.5 5v9z"></path>
    </svg>
  );
}

function Home(props) {
  const { className, width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width = {width}
      height = {height}
      className={className}
    >
      <path d="M16 1L0 17l3 3 3-3v13h8v-6h4v6h8V17l3 3 3-3L16 1zm0 13a2 2 0 11-.001-3.999A2 2 0 0116 14z"></path>
    </svg>
  );
}

function Github() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path d="M16 .395c-8.836 0-16 7.163-16 16 0 7.069 4.585 13.067 10.942 15.182.8.148 1.094-.347 1.094-.77 0-.381-.015-1.642-.022-2.979-4.452.968-5.391-1.888-5.391-1.888-.728-1.849-1.776-2.341-1.776-2.341-1.452-.993.11-.973.11-.973 1.606.113 2.452 1.649 2.452 1.649 1.427 2.446 3.743 1.739 4.656 1.33.143-1.034.558-1.74 1.016-2.14-3.554-.404-7.29-1.777-7.29-7.907 0-1.747.625-3.174 1.649-4.295-.166-.403-.714-2.03.155-4.234 0 0 1.344-.43 4.401 1.64a15.353 15.353 0 014.005-.539c1.359.006 2.729.184 4.008.539 3.054-2.07 4.395-1.64 4.395-1.64.871 2.204.323 3.831.157 4.234 1.026 1.12 1.647 2.548 1.647 4.295 0 6.145-3.743 7.498-7.306 7.895.574.497 1.085 1.47 1.085 2.963 0 2.141-.019 3.864-.019 4.391 0 .426.288.925 1.099.768C27.421 29.457 32 23.462 32 16.395c0-8.837-7.164-16-16-16z"></path>
    </svg>
  );
}

function Envelope() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path d="M32 12.679v14.179a2.866 2.866 0 01-2.857 2.857H2.857A2.866 2.866 0 010 26.858V12.679a10.025 10.025 0 001.804 1.554c2.964 2.018 5.964 4.036 8.875 6.161 1.5 1.107 3.357 2.464 5.304 2.464h.036c1.946 0 3.804-1.357 5.304-2.464 2.911-2.107 5.911-4.143 8.893-6.161.643-.446 1.25-.964 1.786-1.554zm0-5.25c0 2-1.482 3.804-3.054 4.893-2.786 1.929-5.589 3.857-8.357 5.804-1.161.804-3.125 2.446-4.571 2.446h-.036c-1.446 0-3.411-1.643-4.571-2.446-2.768-1.946-5.571-3.875-8.339-5.804-1.268-.857-3.071-2.875-3.071-4.5 0-1.75.946-3.25 2.857-3.25h26.286c1.554 0 2.857 1.286 2.857 2.857z"></path>
    </svg>
  );
}

function CV(props) {
  const { className } = props;
  return (
    <svg  id="Layer_1"  className={className} height="20" viewBox="0 0 100 100 " width="20" xmlns="http://www.w3.org/2000/svg"><path transform="translate(-8 -8) scale(1.16 1.16)" fill="currentColor" d="M83.333 10H16.667C13.001 10 10 13.001 10 16.667v66.667C10 86.999 13.001 90 16.667 90h66.667C86.999 90 90 86.999 90 83.333V16.667C90 13.001 86.999 10 83.333 10zM26.667 36.667c0 -5.521 4.479 -10 10 -10s10 4.479 10 10H40a3.333 3.333 0 1 0 -6.666 0v13.33a3.334 3.334 0 0 0 6.666 0h6.667c0 5.524 -4.479 10 -10 10s-10 -4.477 -10 -10zm46.666 36.666H26.667v-6.666h46.667v6.666zM65 60h-6.667L50 26.667h6.872l4.795 19.18 4.795 -19.18h6.871z"/></svg>
  );
}

export {CV}
export {LinkedIn}
export {Home}
export {Envelope}
export {Twitter}
export {Github}